<template> 
    <v-container id="login" class="text-center d-flex justify-center align-center ma-auto" style="height: fit-content" fluid>
        <v-row class="main pa-0 ma-0">
            <v-col cols="12" sm="10" md="7" lg="5" class="ma-auto pa-0"> 
                <v-card class="ma-0 pa-0"  outlined>
                    <div class="ma-0 pa-0 py-6">
                        <v-img
                            class="mx-4"
                            height="100"
                            contain
                            aspect-ratio="1"
                            :src="storage_url+default_path+company.logo"
                        >
                            <template v-slot:placeholder>
                                <v-row
                                    class="fill-height ma-0"
                                    align="center"
                                    justify="center"
                                >
                                    <v-progress-circular
                                    indeterminate
                                    color="custom-secondary"
                                    ></v-progress-circular>
                                </v-row>
                            </template>
                        </v-img>
                    </div>
                    <v-form ref="form" class="pa-6" v-model="valid" lazy-validation @submit.prevent="login">
                            <v-text-field
                                v-model="user.username"
                                type='text'
                                :label="$t('username')"
                                prepend-inner-icon="mdi-account"
                                outlined
                                required
                                :counter="maxTextfieldLength"
                                :rules="textfieldRules"
                                autocomplete="off"
                                @keydown.enter="login"
                            ></v-text-field>
                            <v-text-field
                                v-model="user.password"
                                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                                :type="show ? 'text' : 'password'"
                                :label="$t('password')"
                                prepend-inner-icon="mdi-lock"
                                style="white-space: pre-line;"
                                outlined
                                required
                                :counter="maxPasswordfieldLength"
                                :rules="passwordfieldRules"
                                autocomplete="off"
                                @click:append="show = !show"
                                @keydown.enter="login"
                            ></v-text-field>
                            <v-btn type="submit" :title="$t('login')" depressed :disabled="!valid" :loading="loading" class="pa-auto ma-auto success custom-accent">
                                <span>{{$t("login")}}</span>
                            </v-btn>
                        </v-form>             
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapActions,mapState,mapGetters } from "vuex";

export default({
    name: 'Login',
    data() {
        return {
            user:{},
            show:false,
            valid: false,

            storage_url: process.env.VUE_APP_STORAGEURL,
            default_path: "default_images/",
        }
    },
    computed: {
        maxTextfieldLength () {
        return 30;
        },
        maxPasswordfieldLength () {
            return 16;
        },
        textfieldRules(){ 
            return [
                v => !!v || this.$t('fieldError'),
                v => v?.length <= this.maxTextfieldLength || this.$t('fieldValidError1')+' '+this.maxTextfieldLength+' '+this.$t('fieldValidError2'),
            ]
        },
        passwordfieldRules(){ 
            const passwordRegex = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/);
            return [
                v => !!v || this.$t('fieldError'),
                v => passwordRegex.test(v) || this.$t('passwordValidError'),
            ]
        },
        loading(){
            return this.login_loading;
        },

         ...mapGetters('company',{
            company:'getCompany'
        }),
        ...mapState({
          login_loading: "loading"
        }),
    },
    methods: {
        login() {
            if(this.$refs.form.validate()){
                this.retrieveToken(this.user).then(() => {
                    this.retrieveUserInfo().then((response)=>{
                        this.$root.$emit('callNotify', { icon:'mdi-check-circle',  content:this.$t("welcome")+', '+response.data.fullname.toUpperCase()+'!', title:this.$t('success'), type:'success'});
                        this.$router.push({ name: 'home' });
                    })
                }).catch(() => {
                    this.$root.$emit('callNotify', { icon:'mdi-alert',  content:this.$t("loginError"), title:this.$t('warning'), type:'warn'});
                });
            }
        },

        ...mapActions('user',["retrieveToken","retrieveUserInfo"]),
    }
})
</script>
<style scoped>
</style>
